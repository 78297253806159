import React, { useState, FC } from 'react';

import Header from '../../hooks/common/header';
import { ElevenLabsKey } from '../../hooks/common/key';
import TextSendButton from '../../hooks/eleventurbo/textsendbutton';
import LabsDisplay from '../../hooks/eleventurbo/labsdisplay';

interface EleventurboProps {
title: string;
roomType: string;
}

const Eleventurbo: FC<EleventurboProps> = ({
title,
roomType
}) => {
const [status, setStatus] = useState<string>('');
const [text, setText] = useState<string>('');
const [prompt, setPrompt] = useState<string>('');
const [voice, setVoice] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [xiApiKey, setXiApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

return (
<>
<Header
title={title}
/>

<ElevenLabsKey
xiApiKey={xiApiKey}
setXiApiKey={setXiApiKey}
setAkNo={setAkNo}
/>

{xiApiKey && (
<>
<TextSendButton
title={title}
text={text}
setText={setText}
setPrompt={setPrompt}
voice={voice}
setVoice={setVoice}
isSending={isSending}
setIsSending={setIsSending}
akNo={akNo}
/>

<LabsDisplay
roomType={roomType}
status={status}
setStatus={setStatus}
prompt={prompt}
setPrompt={setPrompt}
voice={voice}
setIsSending={setIsSending}
xiApiKey={xiApiKey}
/>
</>
)}
</>
);
}

export default Eleventurbo;
