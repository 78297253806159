import React from 'react';

const createUsed = (
promptTokens: number,
completionTokens: number,
totalTokens: number,
reasoningTokens: number
): JSX.Element => {
const newPromptTokens = `prompt tokens: ${promptTokens}`;
const newCompletionTokens = `completion tokens: ${completionTokens}`;
const newTotalTokens = `total tokens: ${totalTokens}`;
const newReasoningTokens = `reasoning tokens: ${reasoningTokens}`;

return (
<div>
<p>{newPromptTokens}</p>
<p>{newCompletionTokens}</p>
<p>{newTotalTokens}</p>
<p>{newReasoningTokens}</p>
<br /><br />
</div>
);
}

export default createUsed;
