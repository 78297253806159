import React from 'react';

import { models } from './model';
import { keyDownHandler } from '../common/keydownhandler';

import { AudioMessages } from '../../@types/messages';

interface ChatSendButtonProps {
title: string;
roomType: string;
model: string;
setModel: React.Dispatch<React.SetStateAction<string>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
messages: AudioMessages[];
setMessages: React.Dispatch<React.SetStateAction<AudioMessages[]>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
}

const ChatSendButton: React.FC<ChatSendButtonProps> = ({
title,
roomType,
model,
setModel,
userInput,
setUserInput,
messages,
setMessages,
isSending,
setIsSending,
apiKey
}) => {
const sendButtonHandler = () => {
if (!userInput.trim()) {
alert('입력한 내용이 없습니다. 요청할 내용을 입력하세요.');
return;
}

setIsSending(true);
setMessages([...messages, { role: 'user', content: userInput }]);
};

return (
<>
<h1>{title}</h1>

{roomType === 'chataudio' && (
<>
<p>audio 모델을 선택하세요.</p>
<p>audio 모델 선택:</p>
<select value={model} onChange={(event) => setModel(event.target.value)} disabled={isSending}>
{models.map((modelOption) => (
<option key={modelOption.value} value={modelOption.value}>
{modelOption.name}
</option>
))}
</select>
<br/><br/>
<p>요청할 내용을 입력하세요.</p>
<p>요청할 내용</p>
<textarea
placeholder="요청할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}
</>
);
};

export default ChatSendButton;
