import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from './error';
import { decodeToUtf8 } from '../common/decode';

interface OpenaiKeyProps {
apiKey: string;
setApiKey: React.Dispatch<React.SetStateAction<string>>;
setAkNo?: (value: number) => void;
}

interface ElevenLabsKeyProps {
xiApiKey: string;
setXiApiKey: React.Dispatch<React.SetStateAction<string>>;
setAkNo?: (value: number) => void;
}

export const OpenaiKey: React.FC<OpenaiKeyProps> = ({
apiKey,
setApiKey,
setAkNo
}) => {
const [uuid] = useState(localStorage.getItem('uuid'));
const [error, setError] = useState('');

useEffect(() => {
const getKey = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/getKeyData`,
{
uuid: uuid
},
{
headers: {
'Content-Type': 'application/json',
},
});
const data = response.data;
if (data[0]?.ak_no !== undefined && setAkNo) {
setAkNo(typeof data[0].ak_no === 'number' ? data[0].ak_no : Number(data[0].ak_no));
}
if (data[0]?.api_key) {
setApiKey(decodeToUtf8(data[0].api_key));
}
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

getKey();
}, [uuid, setApiKey, setAkNo]);

return (
<>
{(!uuid || !apiKey) && (
<div>
<p>API 키가 설정되지 않았습니다.</p>
<a href="/auth">api key 등록하러 가기</a>
</div>
)}
{error && (
<div className="error">
{error}
</div>
)}
</>
);
}

export const ElevenLabsKey: React.FC<ElevenLabsKeyProps> = ({
xiApiKey,
setXiApiKey,
setAkNo
}) => {
const [uuid] = useState(localStorage.getItem('uuid'));
const [error, setError] = useState('');

useEffect(() => {
const getKey = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/getKeyData`,
{
uuid: uuid
},
{
headers: {
'Content-Type': 'application/json',
},
});
const data = response.data;
if (data[0]?.ak_no !== undefined && setAkNo) {
setAkNo(typeof data[0].ak_no === 'number' ? data[0].ak_no : Number(data[0].ak_no));
}
if (data[0]?.xi_api_key) {
setXiApiKey(decodeToUtf8(data[0].xi_api_key));
}
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

getKey();
}, [uuid, setXiApiKey, setAkNo]);

return (
<>
{(!uuid || !xiApiKey) && (
<div>
<p>API 키가 설정되지 않았습니다.</p>
<a href="/auth">api key 등록하러 가기</a>
</div>
)}
{error && (
<div className="error">
{error}
</div>
)}
</>
);
}
