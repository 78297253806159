import React, { useState, FC } from 'react';

import Header from '../../hooks/common/header';
import { ElevenLabsKey } from '../../hooks/common/key';
import TextSendButton from '../../hooks/soundgeneration/textsendbutton';
import SoundDisplay from '../../hooks/soundgeneration/sounddisplay';

interface SoundgenerationProps {
title: string;
roomType: string;
}

const Soundgeneration: FC<SoundgenerationProps> = ({
title,
roomType
}) => {
const [status, setStatus] = useState<string>('');
const [text, setText] = useState<string>('');
const [prompt, setPrompt] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [xiApiKey, setXiApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<ElevenLabsKey
xiApiKey={xiApiKey}
setXiApiKey={setXiApiKey}
/>

{xiApiKey && (
<>
<TextSendButton
title={title}
text={text}
setText={setText}
setPrompt={setPrompt}
isSending={isSending}
setIsSending={setIsSending}
xiApiKey={xiApiKey}
/>

<SoundDisplay
roomType={roomType}
status={status}
setStatus={setStatus}
prompt={prompt}
setPrompt={setPrompt}
setIsSending={setIsSending}
xiApiKey={xiApiKey}
/>
</>
)}
</>
);
}

export default Soundgeneration;
