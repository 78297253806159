import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import { keyDownHandler } from '../common/keydownhandler';

import { ElevenmultilingualOption } from '../../@types/option';

interface TextSendButtonProps {
title: string;
text: string;
setText: React.Dispatch<React.SetStateAction<string>>;
setPrompt: React.Dispatch<React.SetStateAction<string>>;
voice: string;
setVoice: React.Dispatch<React.SetStateAction<string>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
akNo: number;
}

const TextSendButton: React.FC<TextSendButtonProps> = ({
title,
text,
setText,
setPrompt,
voice,
setVoice,
isSending,
setIsSending,
akNo
}) => {
const [voices, setVoices] = useState<ElevenmultilingualOption[]>([]);
const [newVoiceName, setNewVoiceName] = useState<string>('');
const [newVoiceId, setNewVoiceId] = useState<string>('');
const [saveVoiceIdState, setSaveVoiceIdState] = useState<boolean>(false);
const [saveVoiceIdResult, setSaveVoiceIdResult] = useState<string>('');
const [error, setError] = useState<string>('');

useEffect(() => {
const fetchVoices = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/getVoiceIdData`, {
akNo: akNo,
}, {
headers: {
'Content-Type': 'application/json',
},
});
const data = response.data;
if (data[0]?.voice_id) {
setVoices(data);
setVoice(data[0].voice_id);
setSaveVoiceIdState(false);
}
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
};
};

fetchVoices();
}, [setVoice, saveVoiceIdState, akNo]);

const saveVoiceId = async () => {
if (!newVoiceName || !newVoiceId) {
alert('정확한 음성 id를 입력하세요.');
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveVoiceId`, {
voiceName: newVoiceName,
voiceId: newVoiceId,
akNo: akNo,
}, {
headers: {
'Content-Type': 'application/json',
},
});
const result = saveResponse.data;
setSaveVoiceIdResult(result);
setSaveVoiceIdState(true);
setNewVoiceName('');
setNewVoiceId('');
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
};

const sendButtonHandler = () => {
if (!text || !voice) {
alert('변환할 내용을 입력하고 음성을 선택하세요.');
return;
}

setIsSending(true);
setPrompt(text);
};

return (
<>
<h1>{title}</h1>
<p>저장할 음성 이름을 입력하세요.</p>
<p>저장할 음성 이름</p>
<input type="text" placeholder="저장할 음성 이름" value={newVoiceName} onChange={(event) => setNewVoiceName(event.target.value)} />
<br /><br />

<p>저장할 음성 id를 입력하세요.</p>
<p>저장할 음성 id</p>
<input type="text" placeholder="저장할 음성 ID" value={newVoiceId} onChange={(event) => setNewVoiceId(event.target.value)} />
<br /><br />

<button onClick={saveVoiceId} disabled={isSending}>음성 id 저장</button>
<br /><br />

<div aria-live="polite">
{saveVoiceIdResult}
</div>
<br /><br />

<p>변환할 내용을 입력하세요.</p>
<p>변환할 내용</p>
<textarea
placeholder="변환할 내용"
value={text}
onChange={e => setText(e.target.value)}
onKeyDown={keyDownHandler}>
</textarea>
<br /><br />

<p>음성을 선택하세요.</p>
<p>음성 선택</p>
{voices.map((voiceOption) => (
<label key={voiceOption.voice_name}>
<input
type="radio"
name="voice"
value={voiceOption.voice_id}
checked={voiceOption.voice_id === voice}
onChange={(event) => setVoice(event.target.value)}
disabled={isSending}
/>
{voiceOption.voice_name}
</label>
))}
<br /><br />

<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>음성 변환</button>
<br /><br />

{error && (
<div className="error" aria-live="polite">
{error}
</div>
)}
</>
);
}

export default TextSendButton;
