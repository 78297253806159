import React from 'react';

const createChatLog = (
userInput: string,
botResponse: string,
): JSX.Element => {
const newUserInput = userInput.split('\n').map((line, index) => (
<React.Fragment key={`userInput-${index}`}>
{line}
<br />
</React.Fragment>
));

const newBotResponse = botResponse.split('\n').map((line, index) => {
return (
<React.Fragment key={`botResponse-${index}`}>
{line.replace(/\\(['"])/g, '$1')}
<br />
</React.Fragment>
);
});

return (
<>
<div>
<h2>{newUserInput}</h2>
<br />
{newBotResponse}
<br /><br />
</div>
</>
);
}

export default createChatLog;
