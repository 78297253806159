import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import { models } from './model';
import { languages } from './language';
import { selections } from './selection';
import { keyDownHandler } from '../common/keydownhandler';

import { Messages } from '../../@types/messages';

interface ChatSendButtonProps {
title: string;
roomType: string;
model: string;
setModel: React.Dispatch<React.SetStateAction<string>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
file: File | null;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
systemMessage: string;
setSystemMessage: React.Dispatch<React.SetStateAction<string>>;
arriveLanguage: string;
setArriveLanguage: React.Dispatch<React.SetStateAction<string>>;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
isVoiceEnabled: boolean;
setIsVoiceEnabled: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
akNo: number;
}

const ChatSendButton: React.FC<ChatSendButtonProps> = ({
title,
roomType,
model,
setModel,
userInput,
setUserInput,
file,
setFile,
systemMessage,
setSystemMessage,
arriveLanguage,
setArriveLanguage,
messages,
setMessages,
isVoiceEnabled,
setIsVoiceEnabled,
isSending,
setIsSending,
setChatDataLoad,
akNo
}) => {
const [inputKey, setInputKey] = useState<number>(Date.now());
const [saveSystemMessageResult, setSaveSystemMessageResult] = useState('');
const [error, setError] = useState('');

useEffect(() => {
const fetchSystemMessage = async () => {
try {
const response = await axios.post(`${process.env.REACT_APP_API_URL}/getSystemMessageData`, {
akNo: akNo,
roomType: roomType
}, {
headers: {
'Content-Type': 'application/json'
}
});
const data = response.data;
if (data[0]?.system_message) {
setSystemMessage(data[0].system_message);
}
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
}

if (akNo) {
fetchSystemMessage();
}
}, [akNo, roomType, setSystemMessage]);

const saveSystemMessage = async () => {
if (!systemMessage) {
alert('관계 및 테릭터를 입력하세요.');
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveSystemMessage`, {
akNo: akNo,
roomType: roomType,
systemMessage: systemMessage
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveSystemMessageResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
}
}

const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
const selectedFile = event.target.files ? event.target.files[0] : null;

if (selectedFile && selectedFile.type === "text/plain") {
setFile(selectedFile);
const reader = new FileReader();

reader.onload = () => {
if (typeof reader.result === 'string') {
setUserInput(reader.result);
}
};

reader.readAsText(selectedFile);
} else {
alert('텍스트 파일만 업로드 가능합니다.');
setInputKey(Date.now());
}
};

const sendButtonHandler = () => {
if (!userInput.trim()) {
alert('입력한 내용이 없습니다. 요청할 내용을 입력하세요.');
return;
}

setIsSending(true);
setChatDataLoad(true);
setMessages([...messages, { role: 'user', content: userInput }]);
setInputKey(Date.now());
};

return (
<>
<h1>{title}</h1>

{roomType === 'chat' && (
<>
<p>gpt 모델을 선택하세요.</p>
<p>gpt 모델 선택:</p>
<select value={model} onChange={(event) => setModel(event.target.value)} disabled={isSending}>
{models.map((modelOption) => (
<option key={modelOption.value} value={modelOption.value}>
{modelOption.name}
</option>
))}
</select>
<br/><br/>
<p>챗봇의 역할을 입력하세요.</p>
<p>챗봇의 역할</p>
<textarea
placeholder="챗봇의 역할"
value={systemMessage}
onChange={e => setSystemMessage(e.target.value)}
required>
</textarea>
<br/><br/>
<p>요청할 내용을 입력하세요.</p>
<p>요청할 내용</p>
<textarea
placeholder="요청할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}

{roomType === 'specialfriend' && (
<>
<p>본인과 상대방의 관계, 케릭터 묘사 등을 자유롭게 입력하세요.</p>
<p>관계 및 테릭터</p>
<textarea placeholder="관계 및 테릭터" value={systemMessage} onChange={e => setSystemMessage(e.target.value)} required></textarea>
<br/><br/>
<button onClick={saveSystemMessage}>저장</button>
<br/><br/>
<div aria-live="polite">
{saveSystemMessageResult}
</div>
<br/><br/>
<p>상대방에게 하고싶은 말을 입력하세요.</p>
<p>상대방에게</p>
<textarea
placeholder="상대방에게"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}

{roomType === 'translation' && (
<>
<p>번역할 내용을 입력하세요.</p>
<p>번역할 내용</p>
<textarea
placeholder="번역할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<p>도착언어를 선택하세요.</p>
<p>도착언어 선택:</p>
<select value={arriveLanguage} onChange={(event) => setArriveLanguage(event.target.value)} disabled={isSending}>
{languages.map((languageOption) => (
<option key={languageOption.value} value={languageOption.value}>
{languageOption.name}
</option>
))}
</select>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>번역</button>
</>
)}

{roomType === 'englishteacher' && (
<>
{setModel('gpt-4o')}
<p>영어 선생님께 하고싶은 말을 입력하세요.</p>
<p>영어 선생님께</p>
<textarea
placeholder="영어 선생님께"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}

{roomType === 'frenchteacher' && (
<>
{setModel('gpt-4o')}
<p>프랑스어 선생님께 하고싶은 말을 입력하세요.</p>
<p>프랑스어 선생님께</p>
<textarea
placeholder="프랑스어 선생님께"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}

{roomType === 'cooking' && (
<>
<p>검색할 음식 이름을 입력하세요.</p>
<p>검색할 음식 이름</p>
<textarea
placeholder="검색할 음식 이름"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>검색</button>
</>
)}

{roomType === 'summingup' && (
<>
{setModel('gpt-4o')}
<p>요약할 내용을 입력하세요.</p>
<p>요약할 내용</p>
<textarea
placeholder="요약할 내용"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>요약</button>
</>
)}

{roomType === 'summingupdoc' && (
<>
{setModel('gpt-4o')}
<p>요약할 .txt 파일을 선택하세요.</p>
<p>요약할 .txt 파일</p>
<input type="file" key={inputKey} onChange={fileChangeHandler} />
<p>{file ? file.name : "파일이 선택되지 않았습니다."}</p>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>요약</button>
</>
)}

{roomType === 'subject' && (
<>
<p>주제 생성을 위한 키워드를 입력하세요.</p>
<p>주제 생성을 위한 키워드</p>
<textarea
placeholder="주제 생성을 위한 키워드"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>생성</button>
</>
)}

{roomType === 'mbti' && (
<>
{setModel('gpt-4o')}
{messages[messages.length - 1]?.role === 'system' && (
<>
{setUserInput('시작')}
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button onClick={sendButtonHandler} disabled={isSending}>시작</button>
</>
)}
{messages[messages.length - 1]?.role === 'assistant' && (
<>
<p>선택지</p>
{selections.map((selectionOption) => (
<label key={selectionOption.value}>
<input
type="radio"
name="selection"
value={selectionOption.value}
checked={selectionOption.value === userInput}
onChange={(event) => setUserInput(event.target.value)}
disabled={isSending}
/>
{selectionOption.name}
</label>
))}
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>제출</button>
</>
)}
</>
)}

{roomType === 'businessmail' && (
<>
{setModel('gpt-4o')}
{messages[messages.length - 1]?.role === 'system' && (
<>
{setUserInput('시작')}
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button onClick={sendButtonHandler} disabled={isSending}>시작</button>
</>
)}
{messages[messages.length - 1]?.role === 'assistant' && (
<>
<p>메일 작성에 필요한 정보를 입력하세요.</p>
<p>메일 작성에 필요한 정보</p>
<textarea
placeholder="메일 작성에 필요한 정보"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>전송</button>
</>
)}
</>
)}

{roomType === 'decision' && (
<>
{setModel('gpt-4o')}
<p>결정하지 못해 고민 중인 것에 대해 입력하세요.</p>
<p>결정하지 못해 고민 중인 것</p>
<textarea
placeholder="결정하지 못해 고민 중인 것"
value={userInput}
onChange={e => setUserInput(e.target.value)}
onKeyDown={keyDownHandler}
required>
</textarea>
<br/><br/>
<input type="checkbox" checked={isVoiceEnabled} onChange={e => setIsVoiceEnabled(e.target.checked)} disabled={isSending} placeholder="음성 출력 사용" />
<br/><br/>
<button id="send-button" onClick={sendButtonHandler} disabled={isSending}>요청</button>
</>
)}

<br/><br/>
{error && (
<div className="error" aria-live="polite">
{error}
</div>
)}
</>
);
};

export default ChatSendButton;
