import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import TextSendButton from '../../hooks/tts/textsendbutton';
import TtsDisplay from '../../hooks/tts/ttsdisplay';

interface TtsProps {
title: string;
roomType: string;
}

const Tts: React.FC<TtsProps> = ({
title,
roomType
}) => {
const [status, setStatus] = useState<string>('');
const [text, setText] = useState<string>('');
const [prompt, setPrompt] = useState<string>('');
const [voice, setVoice] = useState<string>('nova');
const [isSending, setIsSending] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
/>

{apiKey && (
<>
<TextSendButton
title={title}
text={text}
setText={setText}
setPrompt={setPrompt}
voice={voice}
setVoice={setVoice}
isSending={isSending}
setIsSending={setIsSending}
/>

<TtsDisplay
roomType={roomType}
status={status}
setStatus={setStatus}
prompt={prompt}
setPrompt={setPrompt}
voice={voice}
setIsSending={setIsSending}
apiKey={apiKey}
/>
</>
)}
</>
);
};

export default Tts;
