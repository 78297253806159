import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';

import { AudioMessages } from '../../@types/messages';

interface ChatDisplayProps {
roomType: string;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: AudioMessages[];
setMessages: React.Dispatch<React.SetStateAction<AudioMessages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
audioSrc: string;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
apiKey: string;
akNo: number;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
chatLog,
setChatLog,
model,
messages,
setMessages,
userInput,
setUserInput,
audioSrc,
setAudioSrc,
setIsSending,
apiKey,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setChatLog('잠시만 기다려 주세요.');

let data = {
model: model,
modalities: ["text", "audio"],
audio: { voice: "nova", format: "wav" },
messages: messages,
};

const config = {
headers: {
Authorization: `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let audioId: string;
let audioResponse: string;

try {
const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.audio.transcript;
audioId = response.data.choices[0].message.audio.id;
audioResponse = response.data.choices[0].message.audio.data;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

setChatLog(createChatLog(userInput, botResponse));
setAudioSrc(`data:audio/wav;base64,${audioResponse}`);
setMessages([
...messages,
{ role: 'assistant',
audio: {
id: audioId
}
}
]);
setUserInput('');
setIsSending(false);
};

if (messages[messages.length - 1]?.role === 'user' && !error) {
SendRequest();
}
}, [roomType, setChatLog, model, messages, setMessages, userInput, setUserInput, setAudioSrc, setIsSending, apiKey, akNo, error, setError]);

return (
<>
{chatLog}
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
{audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
</>
);
};

export default ChatDisplay;
