import React from 'react';

const Main: React.FC = () => {
return (
<>
<p>환영합니다.</p>
<br />
<p><a href="/auth">api key 등록</a></p>
<br/><br/>
<h1>제공되는 서비스</h1>
<br />

<h2>openai</h2>
<nav>
<ul>
<li>gpt
<ul>
<li><a href="/chat">챗봇</a></li>
<li><a href="/specialfriend">가상 연인</a></li>
<li><a href="/translation">번역</a></li>
<li><a href="/englishteacher">영어 공부</a></li>
<li><a href="/frenchteacher">프랑스어 공부</a></li>
<li><a href="/cooking">요리 레시피</a></li>
<li><a href="/summingup">텍스트 요약</a></li>
<li><a href="/summingupdoc">문서 요약</a></li>
<li><a href="/subject">주제 생성</a></li>
<li><a href="/mbti">MBTI 성격 유형 검사</a></li>
<li><a href="/businessmail">비즈니스 메일 작성</a></li>
<li><a href="/decision">결정 봇</a></li>
</ul>
</li>

<li>gpt-4o-audio
<ul>
<li><a href="/chataudio">챗봇</a></li>
</ul>
</li>

<li>o1
<ul>
<li><a href="/chato1">챗봇</a></li>
</ul>
</li>

<li>tts
<ul>
<li><a href="/ttsdownload">텍스트 음성 변환후 다운로드</a></li>
<li><a href="/ttsplay">텍스트 음성 변환후 재생</a></li>
</ul>
</li>

<li>whisper
<ul>
<li><a href="/transcript">음성 텍스트 변환</a></li>
<li><a href="/englishtranslation">음성 영문 번역</a></li>
</ul>
</li>

<li>dall-e
<ul>
<li><a href="/imagecreation">이미지 생성</a></li>
<li><a href="/imagevariation">이미지 변형</a></li>
</ul>
</li>
</ul>
</nav>
<br />

<h2>elevenlabs</h2>
<nav>
<ul>
<li>elevenmultilingual
<ul>
<li><a href="/labsdownload">텍스트 음성 변환후 다운로드</a></li>
<li><a href="/labsplay">텍스트 음성 변환후 재생</a></li>
</ul>
</li>

<li>eleventurbo
<ul>
<li><a href="/labsdownloadbeta">텍스트 음성 변환후 다운로드(베타)</a></li>
<li><a href="/labsplaybeta">텍스트 음성 변환후 재생(베타)</a></li>
</ul>
</li>

<li>soundgeneration
<ul>
<li><a href="/sounddownload">사운드 생성후 다운로드</a></li>
<li><a href="/soundplay">사운드 생성후 재생</a></li>
</ul>
</li>
</ul>
</nav>
</>
);
}

export default Main;
