import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import ChatSendButton from '../../hooks/audio/chatsendbutton';
import ChatInitButton from '../../hooks/audio/chatinitbutton';
import ChatDisplay from '../../hooks/audio/chatdisplay';

import { AudioMessages } from '../../@types/messages';

interface AudioProps {
title: string;
roomType: string;
}

const Audio: React.FC<AudioProps> = ({
title,
roomType
}) => {
const [model, setModel] = useState<string>('gpt-4o-audio-preview');
const [userInput, setUserInput] = useState<string>('');
const [messages, setMessages] = useState<AudioMessages[]>([]);
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [audioSrc, setAudioSrc] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
setAkNo={setAkNo}
/>

{apiKey && (
<>
<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
messages={messages}
setMessages={setMessages}
isSending={isSending}
setIsSending={setIsSending}
apiKey={apiKey}
/>

<ChatInitButton
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
setAudioSrc={setAudioSrc}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
chatLog={chatLog}
setChatLog={setChatLog}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
audioSrc={audioSrc}
setAudioSrc={setAudioSrc}
setIsSending={setIsSending}
apiKey={apiKey}
akNo={akNo}
/>
</>
)}
</>
);
}

export default Audio;
